<template>
  <div class="layoutContent">
    <HomeTitle :description="description" />
    <div class="contentParty">
      <KValidationObserver ref="observer">
        <k-alert-msg ref="lblMsg" />
        <k-form @keydown.enter="isPrevent" @submit.prevent="onSubmit">
          <k-form-group
            label-suffix="documentName"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
            label-for="documentName"
          >
            <div class="col-form-label">
              {{ form.documentName }}
            </div>
          </k-form-group>
          <k-form-group
            label-suffix="instructions"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
            label-for="instructions"
          >
            <div class="col-form-label">
              {{ form.instructions }}
            </div>
          </k-form-group>
          <k-form-group
            label-suffix="relatedFiles"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
            label-for="relatedFiles"
          >
            <div class="col-form-label">
              <k-list-group class="document-detail">
                <k-list-group-item
                  v-for="(item, index) in form.relatedFiles"
                  :key="index"
                >
                  <k-link @click="downloadPath(item.id)">
                    {{ item.displayName }}
                  </k-link>
                </k-list-group-item>
              </k-list-group>
            </div>
          </k-form-group>
          <k-form-group
            label-suffix="dueDate"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
            label-for="dueDate"
          >
            <div class="col-form-label">
              {{ form.dueDate }}
            </div>
          </k-form-group>
          <k-form-group
            label-class="require-mark"
            label-suffix="uploadFile"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
            label-for="uploadFile"
          >
            <KValidationProvider
              :rules="{
                required: true,
                acceptFileType: { fileAccept: uploadFilesInfo.accept },
                maxFileSize: uploadFilesInfo.size,
                maxFileNameLength: 100,
              }"
              v-slot="{ errors }"
              slim
              name="uploadFile"
              ref="uploadFile"
            >
              <k-alert :show="errors.length > 0" variant="warning">
                {{ errors[0] }}
              </k-alert>
              <common-upload
                v-if="hasUploadFile"
                ref="fileinput"
                v-model="form.uploadFile"
                :accept="uploadFilesInfo.accept"
                :size="uploadFilesInfo.size / 1024"
                :is-multiple="true"
                :on-delete="onDelete"
                :placeholder="uploadFilesInfo.placeholder"
                :show-message="false"
                :key="uploadFilesInfo.uploadKey"
                :is-use-internal-validate="false"
                :plaintext="isPlaintext"
              />
            </KValidationProvider>
          </k-form-group>
          <k-form-group
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
          >
            <k-button
              variant="outline-secondary"
              @click="backClickEvent()"
              type="button"
            >
              back
            </k-button>
            <k-button variant="success" class="ml-5" type="submit">
              submit
            </k-button>
          </k-form-group>
        </k-form>
      </KValidationObserver>
    </div>
    <k-popwindow
      id="popViewRejectionReason"
      :title="$g('reject')"
      :hideOk="true"
    >
    </k-popwindow>
  </div>
</template>

<script>
import { pageNameEnum } from "@/utils/enums/pageNameEnum";
import HomeTitle from "@/views/components/homeTitle";
import { isPrevent, repResult } from "fe-infrastractures";
import { getPortalPageSetting } from "@/api/home";
import {
  getRequiredDocumensDetail,
  saveRequiredDocumensEdit,
  getRequiredDocumensEditUploadFilesInfo,
  documentIsExit,
  downloadDocumentUrl,
  uploadFile,
} from "@/api/requiredDocuments.js";
export default {
  components: {
    HomeTitle,
  },
  props: {
    isPlaintext: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isPrevent,
      description: "",
      form: {},
      uploadFilesInfo: {
        accept: "",
        size: 0,
        placeholder: this.$g("noFileChosen"),
        uploadKey: 0,
      },
      hasUploadFile: false,
    };
  },
  computed: {
    dataSource() {
      return this.getItems;
    },
  },
  created() {
    this.initForm();
    this.initDocInfo();
    getPortalPageSetting(pageNameEnum.RequiredDocumentsSubmit).then((data) => {
      this.description = data.data.description;
    });
  },
  methods: {
    backClickEvent() {
      let query = {
        tabIndex: this.$route.query.tabIndex,
      };
      this.$router.push({
        name: "requiredDocuments",
        query: query,
        params: {
          tabIndex: this.$route.query.tabIndex,
        },
      });
    },
    initDocInfo() {
      getRequiredDocumensEditUploadFilesInfo().then((result) => {
        this.uploadFilesInfo = {
          accept: result.data.acceptTypes,
          size: result.data.maxSize,
          placeholder: this.$g("noFileChosen"),
          uploadKey: 0,
        };
      });
    },
    initForm() {
      let query = {
        requiredDocumentationProcessingId: this.$route.query.detailId,
        electionId: this.$route.query.electionId,
      };
      getRequiredDocumensDetail(query).then((result) => {
        this.form = result.data;
        for (const item of this.form.uploadFile) {
          item.fileName = item.displayName;
          item.documentId = item.id;
        }
        this.hasUploadFile = true;
      });
    },
    onDelete(file) {
      if (file?.id) {
        let index = this.form.uploadFile.findIndex((c) => c.id == file.id);
        this.form.uploadFile.splice(index, 1);
      }
    },
    async onSubmit() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        let pmOpt = this.$confirm({
          title: this.$g("const.confirm.confirm"),
          content: this.$g("const.confirm.save"),
          cancelType: "secondary",
          okText: this.$g("const.confirm.saveYes"),
          cancelText: this.$g("const.confirm.no"),
        });
        if (pmOpt == null) {
          return;
        }
        pmOpt.then(async () => {
          let filesFormData = new FormData();
          let formData = new FormData();
          for (const key in this.form.uploadFile) {
            filesFormData = new FormData();
            if (!this.form.uploadFile[key].id) {
              filesFormData.append("Title", this.form.uploadFile[key].name);
              filesFormData.append("File", this.form.uploadFile[key]);
              formData.append(
                `documentIds[${key}]`,
                (await uploadFile(filesFormData)).data.info
              );
            } else {
              formData.append(
                `documentIds[${key}]`,
                this.form.uploadFile[key].id
              );
            }
          }
          formData.append(
            "requiredDocumentationProcessingId",
            this.form.requiredDocumentationProcessingId
          );
          if (
            this.$route.query.electionId &&
            this.$route.query.electionId !=
              "00000000-0000-0000-0000-000000000000"
          ) {
            formData.append("electionId", this.$route.query.electionId);
          }
          saveRequiredDocumensEdit(formData).then((result) => {
            if (result.data.status == repResult.success) {
              this.$router.push({
                name: "requiredDocumentsSubmitSuccessfully",
              });
            } else {
              this.$refs.lblMsg.message(result.data.status, result.message);
            }
          });
        });
      }
    },
    downloadPath(documentId) {
      let model = {
        DocumentId: documentId,
        ...this.downloadParams,
      };
      documentIsExit(model).then((m) => {
        if (m.data.status === repResult.success) {
          downloadDocumentUrl(model);
        } else {
          this.$refs.lblMsgDocumentDetail.message(m.data.status, m.message);
        }
      });
    },
  },
};
</script>

<style>
.document-detail {
  line-height: 2.4em;
}
</style>
